<template>
  <!-- 基本设定 -->
  <div class="entryBase">
    <el-card class="entryBase-card" v-loading="$store.state.loading">
      <div class="row">
        <span class="tab-title">剧本名称<span style="color: red">*</span></span>
        <el-input
          v-model="model.scenarioName"
          size="small"
          style="margin-left: 20px; max-width: 200px"
        ></el-input>
        <div class="scenario-text">剧本Id： {{model.id}}</div>
      </div>
      <el-divider></el-divider>

      <div class="row">
        <span class="tab-title">封面<span style="color: red">*</span></span>
        <choice-image
          style="margin-left: 20px"
          @surePush="changeImage"
          :viewUrl="model.pictureVo ? model.pictureVo.picturePath : ''"
        />
        <choice-image
          style="margin-left: 20px"
          @surePush="changeImage2"
          :viewUrl="
            model.detailBottomPictureVo
              ? model.detailBottomPictureVo.picturePath
              : ''
          "
          text="详情页底图"
        />
      </div>
      <el-divider></el-divider>

      <div class="row">
        <span class="tab-title">预计时间<span style="color: red">*</span></span>
        <el-input
          v-model="model.predictTime"
          size="small"
          style="margin-left: 20px; max-width: 100px"
        ></el-input>
        <span style="margin-left: 6px">分钟</span>
      </div>
      <el-divider></el-divider>
      <!-- 字数 -->
      <div class="row">
        <span class="tab-title">人均字数<span style="color: red">*</span></span>
        <el-input
          v-model="model.avgWordNum"
          type="number"
          size="small"
          style="margin-left: 20px; max-width: 100px"
        ></el-input>
        <span style="margin-left: 6px">字数</span>
      </div>
      <el-divider></el-divider>

      <div class="row">
        <span class="tab-title">人数</span>
        <el-input-number
          v-model="model.playNum"
          style="margin-left: 20px"
          size="mini"
          controls-position="right"
          :min="1"
          :max="10"
        ></el-input-number>
      </div>
      <el-divider></el-divider>

      <div class="row">
        <span class="tab-title">幕</span>
        <el-input-number
          v-model="model.chapterNum"
          style="margin-left: 20px"
          size="mini"
          controls-position="right"
          :min="1"
          :max="10"
        ></el-input-number>
      </div>
      <el-divider></el-divider>

      <div class="row">
        <span class="tab-title">题材<span style="color: red">*</span></span>
        <span
          class="tc-btn-list"
          style="
            margin-left: 20px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
          "
        >
          <el-button
            style="margin: 0 10px 10px 0"
            type="primary"
            size="mini"
            v-for="(item, i) in tcList"
            :key="i"
            :plain="isPlain(item)"
            @click="choiceTx(item)"
          >
            {{ item.dictLabel }}
          </el-button>
          <el-input
            v-model="model.elseStyle"
            :disabled="isChooseOther"
            placeholder="其他：#输入题材名称"
            size="mini"
            style="max-width: 200px; margin-top: 10px"
          ></el-input>
        </span>
      </div>
      <el-divider></el-divider>
      <!-- 难度 -->
      <div class="row" style="align-items: center">
        <span class="tab-title">难度</span>
        <el-button
          style="margin-left: 20px"
          type="primary"
          size="mini"
          v-for="(item, i) in difficultList"
          :key="i"
          :plain="model.difficulty != item.dictValue"
          @click="choiceNd(item)"
        >
          {{ item.dictLabel }}
        </el-button>
      </div>
      <el-divider></el-divider>

      <div style="display: flex; padding: 12px 0">
        <span class="tab-title">简介<span style="color: red">*</span></span>
        <div style="margin-left: 20px; width: calc(100% - 80px)">
          <div style="display: flex; justify-content: flex-end">
            <edit-text
              :content="model.scenarioSynopsis"
              @surePush="changeSynopsis"
            />
          </div>
          <div class="eb-text-area" v-html="model.scenarioSynopsis"></div>
        </div>
      </div>

      <div style="text-align: right">{{ model.scenarioSynopsis.length }}字</div>

      <div style="display: flex; padding: 12px 0">
        <span class="tab-title">作者<span style="color: red">*</span></span>
        <div style="margin-left: 20px; width: calc(100% - 80px)">
          <el-select v-model="model.authorPerson" size="mini" multiple>
            <el-option
              v-for="(option, oi) in authorList"
              :key="oi"
              :label="option.authorName"
              :value="option.id"
            ></el-option>
          </el-select>
        </div>
      </div>

      <div style="display: flex; padding: 12px 0">
        <span class="tab-title">发行<span style="color: red">*</span></span>
        <div style="margin-left: 20px; width: calc(100% - 80px)">
          <el-select v-model="model.issue" placeholder="" size="mini">
            <el-option
              v-for="(option, oi) in issueList"
              :key="oi"
              :label="option.dictLabel"
              :value="option.dictValue"
            ></el-option>
          </el-select>
        </div>
      </div>

      <div style="display: flex; padding: 12px 0">
        <span class="tab-title">编辑<span style="color: red">*</span></span>
        <div style="margin-left: 20px; width: calc(100% - 80px)">
          <el-select v-model="model.writerPersons" size="mini" multiple>
            <el-option
              v-for="(option, oi) in authorizedList"
              :key="oi"
              :label="option.nickname"
              :value="option.id"
            ></el-option>
          </el-select>
        </div>
      </div>

      <div style="height: 50px"></div>
    </el-card>

    <!-- 保存 -->
    <div
      v-if="isEdit"
      style="
        position: absolute;
        bottom: -20px;
        right: 30px;
        display: flex;
        justify-content: flex-end;
        margin-top: 10px;
      "
    >
      <el-button
        @click="saveBefore"
        style="
          border: 1px solid rgba(250, 225, 238, 1);
          background: rgba(250, 225, 238, 1);
          color: #333;
          font-weight: bold;
        "
        type="warning"
        v-loading="loading"
        :disabled="loading"
        >保存</el-button
      >
    </div>
  </div>
</template>

<script>
import choiceImage from "@/components/common/choiceImage";
import editText from "@/components/common/editText";
export default {
  components: {
    choiceImage,
    editText,
  },
  data() {
    return {
      model: {
        scenarioName: "", // 剧本名称
        pictureVo: {
          id: 0,
          picturePath: "",
        }, // 封面素材对象
        predictTime: 100, // 预计时间
        playNum: 2, // 预计人数
        chapterNum: 1, // 幕
        difficulty: "1", //难度
        scenarioStyleIds: [], // 题材ID
        elseStyle: "", // 其他自定义题材
        scenarioSynopsis: "请输入简介",
        issue: "", // 发行
        writerPersons: [], // 编辑列表
        authorPerson: [], // 作者列表
        avgWordNum: '', //人均字数
      },
      loading: false,
      loadingTimer: null,
      authorList: [], // 作者列表
      authorizedList: [], // 编辑列表
      issueList: [], // 发行商列表
      curChapterNum: 0,
    };
  },
  watch: {
    loading(val) {
      if (val) {
        this.loadingTimer = setTimeout(() => {
          this.loading = false;
        }, 5000);
      } else {
        clearTimeout(this.loadingTimer);
      }
    },
  },
  created() {
    this.model.id = localStorage.getItem("scenarioId");
    if (this.model.id) {
      this.getBase();
    }
    // 查询作者和编辑
    this.getAuthor();
  },
  computed: {
    tcList() {
      return this.$store.state.dict.list1;
    },
    difficultList() {
      return this.$store.state.dict.list5;
    },
    isChooseOther() {
      return !this.model.scenarioStyleIds.some((item) => item == 1);
    },
    isEdit() {
      return (
        (sessionStorage.getItem("isEdit") &&
          JSON.parse(sessionStorage.getItem("isEdit"))) ||
        (sessionStorage.getItem("privateFlag") &&
          JSON.parse(sessionStorage.getItem("privateFlag")))
      );
    },
  },
  methods: {
    async getAuthor() {
      const res1 = await this.getRequest.getAuthorList();
      this.authorList = res1.data;
      const res2 = await this.getRequest.getAuthorizedList({
        limit: 1000,
        page: 1,
      });
      this.authorizedList = res2.data;
      const res3 = await this.getRequest.getDict("sys_scenario_issue");
      this.issueList = res3.datas;
      this.issueList.forEach((val) => {
        val.dictValue = Number(val.dictValue);
      });
    },
    choiceTx(item) {
      // 选择和反选题材
      let value = parseInt(item.dictValue);
      let inArr = this.model.scenarioStyleIds.indexOf(value);
      if (inArr != -1) {
        this.model.scenarioStyleIds.splice(inArr, 1);
      } else {
        this.model.scenarioStyleIds.push(value);
      }
    },
    choiceNd(item) {
      this.model.difficulty = item.dictValue;
    },
    isPlain(item) {
      // 是否选中题材
      return !this.model.scenarioStyleIds.some((res) => {
        if (res == item.dictValue) {
          return true;
        }
      });
    },
    isPlainNd(item) {
      return this.model.difficult === item.dictValue;
    },
    changeImage(model) {
      this.model.pictureVo = model;
    },
    changeImage2(model) {
      this.model.detailBottomPictureVo = model;
    },
    changeSynopsis(val) {
      this.model.scenarioSynopsis = val;
    },
    async getBase() {
      this.$store.dispatch("setLoading", true);
      // 查询剧本信息
      const res = await this.getRequest.getScenarioBase(this.model.id);
      this.model = res.datas;
      this.curChapterNum = res.datas.chapterNum;
      this.$emit("updateScenarioName", res.datas.scenarioName);
      this.$store.dispatch("setLoading", false);
      this.$store.commit("setPlayNum", res.datas.playNum);
    },

    async save() {
      const model = this.model;
      model.elseStyle = this.isChooseOther ? "" : model.elseStyle;
      this.loading = true;
      let fun = "scenario";
      if (this.model.id) {
        fun = "updateScenario";
      }
      try {
        const res = await this.getRequest[fun](model);
        if (res.respCode === 0) {
          this.$message({
            message: "保存成功！",
            type: "success",
          });
          if (fun === "scenario") {
            localStorage.setItem("scenarioId", res.datas.id);
            model.id = res.datas.id;
            this.$emit("afterSave", 1, res.datas.id, model.scenarioName);
          } else {
            this.$emit("updateScenarioName", model.scenarioName);
          }
          this.$store.commit("setPlayNum", model.playNum);
          this.curChapterNum = model.chapterNum;
        } else {
          this.$message({
            message: res.respMsg,
            type: "error",
          });
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
    saveBefore() {
      // 更新或新增剧本基本信息
      if (!this.model.scenarioName) {
        this.$message({
          message: "请输入剧本名",
          type: "warning",
        });
        return;
      }
      if (this.model.chapterNum < this.curChapterNum) {
        this.$confirm(
          "当前设置幕数比之前低，会删除多出幕数的数据, 是否继续?",
          "警告",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        )
          .then(() => {
            this.save();
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      } else {
        this.save();
      }
    },
  },
};
</script>

<style lang='scss' scoped>
.el-divider {
  background: rgba(236, 234, 250, 1);
}
.entryBase {
  height: 100%;
  position: relative;
  .entryBase-card {
    height: 100%;
    padding: 15px 0;
    overflow-y: scroll;
    .row {
      display: flex;
      align-items: center;
      padding: 12px 0;
    }
  }
  .tab-title {
    min-width: 80px;
    height: 24px;
    color: rgba(51, 51, 51, 1);
    background: rgba(236, 234, 250, 1);
    border-radius: 4px;
    padding: 0 4px;
    line-height: 24px;
    text-align: center;
  }
}
.eb-text-area {
  margin-top: 10px;
  overflow-y: scroll;
  border-radius: 4px;
  border: 1px solid rgba(197, 192, 224, 1);
  height: 300px;
  padding: 0 10px;
}
.scenario-text {
  color: #999;
  font-size: 14px;
  margin-left: 20px;
}
</style>

<style lang='sass' scoped>
::v-deep .tc-btn-list .el-button--primary.is-plain:hover, .el-button--primary.is-plain:focus
  background: #f2f1f8
  color: #7F76BD
</style>